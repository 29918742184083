<template>
  <div class="book-appointment-container">
    <div class="organization-details">
      <h1>Package Description</h1>
    </div>
    <div class="appointment-progressbar-container">
      <ul class="progress-steps">
        <li class="progress-step">
          <div class="progress-step-title">
            Confirm date & time
          </div>
        </li>
        <li class="progress-step">
          <div class="progress-step-title">
            Confirm date & time
          </div>
        </li>
        <li class="progress-step">
          <div class="progress-step-title">
            Confirm date & time
          </div>
        </li>
      </ul>
    </div>
    <div class="booking-appointment-success-container">
      <div class="booking-appointment-success-img">
        <svg version="1.1" width="100%" height="100%" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g>
            <g>
              <path d="M383.841,171.838c-7.881-8.31-21.02-8.676-29.343-0.775L221.987,296.732l-63.204-64.893
                c-8.005-8.213-21.13-8.393-29.35-0.387c-8.213,7.998-8.386,21.137-0.388,29.35l77.492,79.561
                c4.061,4.172,9.458,6.275,14.869,6.275c5.134,0,10.268-1.896,14.288-5.694l147.373-139.762
                C391.383,193.294,391.735,180.155,383.841,171.838z"/>
            </g>
            </g>
                    <g>
            <g>
              <path d="M256,0C114.84,0,0,114.84,0,256s114.84,256,256,256s256-114.84,256-256S397.16,0,256,0z M256,470.487
                c-118.265,0-214.487-96.214-214.487-214.487c0-118.265,96.221-214.487,214.487-214.487c118.272,0,214.487,96.221,214.487,214.487
                C470.487,374.272,374.272,470.487,256,470.487z"/>
            </g>
            </g>
            </svg>
      </div>
      <p class="booking-appointment-success-title">
        Your booking is confirmed!
      </p>
      <p class="booking-appointment-notes">
        Please check your email for details
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
@import "@/assets/scss/variables.scss";
.book-appointment-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 35px;

  .booking-appointment-success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 100px;

    .booking-appointment-success-img {
      max-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: $lightGreen;
      }
    }
    .booking-appointment-success-title {
      margin-top: 30px;
      font-size: 20px;
      font-weight: 700;
      color: $darkGreen;
    }
    .booking-appointment-notes {
      margin-top: 20px;
    }
  }

  .organization-details {
    background-color: $darkGreen;
    text-align: center;
    padding: 30px 0;
    h1 {
      color: $white;
      font-size: 32px;
    }
  }

  // move to component
  .appointment-progressbar-container {
    margin: 50px 0 70px 0;

    .progress-steps {
      display: flex;
      align-items: center;
      counter-reset: step;
      .progress-step {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $darkGreen;
        font-size: 16px;
        font-weight: 700;

        &:before {
          content: counter(step);
          counter-increment: step;
          width: 50px;
          height: 50px;
          display: block;
          margin: 0 auto;
          border-radius: 50%;
          line-height: 48px;
          font-size: 20px;
          background-color: $darkGreen;
          color: $white;
          text-align: center;
          font-weight: bold;
        }

        .progress-step-title {
          padding: 0 70px 0 10px;
          text-align: center;
        }
        .progress-active-step-title {
          background-color: #016666;
          color: white;
        }
      }

      @media (max-width: 980px) {
        justify-content: space-between;
        .progress-step {
          flex-direction: column;

          div.progress-step-title {
            padding: 10px 0 !important;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .book-appointment-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
